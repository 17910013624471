.navbar-brand{
  margin-left: 15px;
  img{ max-height: 160px;}
}
.navbar { background: none!important; min-height: 130px; margin-bottom: 60px; border: none;}
.navbar-nav {margin: 38px -15px;}
.navbar-collapse {border: none!important; }
.in, .collapsing {
 margin-top:0!important;
  ul{
    background: rgba(255,255,255,0.5)!important;
    padding-right: 1rem!important;
    li{
      text-align: right;
      a{color: #111!important; font-weight: 700;}
      &:hover, &:focus{background: rgba(255,255,255,0.9)!important;}
    }
  }}
